// @ts-check
import qs from "qs";
import iframeResize from "iframe-resizer/js/iframeResizer";

const CANCEL_BOOKING_BTN_ID = 'cancelBookingBtn';
const LOGIN_BTN_ID = 'loginBtn';
const CONTAINER_ID = 'bokamera-embedded-profile';

const OPTION_CANCEL_BTN = 'cancelButton';
const KEYCLOAK_LOGIN_IFRAME_HEIGHT = '900px';

function addOrchestratorScript() {
  const scriptId = 'orchestrator';
  const redirectAuthScript = document.createElement("script");
  const appEnvironment = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : 'test';
  
  redirectAuthScript.type = "text/javascript";
  redirectAuthScript.id = scriptId;
  redirectAuthScript.src = `${process.env.REACT_APP_BASE_URL}/orchestrator.${appEnvironment}.js`;

  if(!document.getElementById(scriptId)) {
    document.head.appendChild(redirectAuthScript);
  }
}

function addButtons() {
  const container = document.getElementById(CONTAINER_ID);

  const loginButtonText = container && container.dataset && container.dataset.loginButtonText
    ? container.dataset.loginButtonText
    : 'Login';
  
  if(!container) {
    console.error(`You must have an element with an id of bokamera-embedded-profile`)
    return;
  }

  const cancelButtonEnabled = container.dataset[OPTION_CANCEL_BTN];
  const loginButton = document.createElement("button");
  loginButton.id = LOGIN_BTN_ID;
  loginButton.setAttribute(
    'style',
    "display: block;background: var(--bokamera-primary, rgb(0, 123, 255));border: 1px solid var(--bokamera-primary, rgb(0, 123, 255));color: #ffffff;width: 400px;border-radius: 4px;padding: 6px 12px;margin: 0px auto 20px;clear: both;font-size: 16px;font-family: \"Roboto\",sans-serif; width: 55%;"
  )
  loginButton.innerHTML = loginButtonText;
  loginButton.onclick = function (ev) {
    const redirectUri = new URL(window.location.href);
    redirectUri.search = '';
    redirectUri.hash = '';
    const backUri = redirectUri.href.endsWith('/') ? redirectUri.href.slice(0, -1) : redirectUri.href;

    removeButtons();

    window.startApp(backUri);
  };

  const cancelBookingButton = document.createElement("button");
  cancelBookingButton.id = CANCEL_BOOKING_BTN_ID;
  const appUrl = process.env.REACT_APP_BASE_URL;
  cancelBookingButton.setAttribute('style', "display: block; background: none; border: 1px solid var(--bokamera-primary, rgb(0, 123, 255)); color: var(--bokamera-primary, rgb(0, 123, 255)); width: 400px; border-radius: 4px; padding: 6px 12px; margin: 0px auto 20px; clear: both;font-size: 16px;font-family: \"Roboto\",sans-serif;");
  cancelBookingButton.innerHTML = `Avboka`;
  cancelBookingButton.onclick = function (ev) {
    const iframe = document.createElement("iframe");
    const targetOrigin = window.BOKAMERA &&
      window.BOKAMERA.configuration &&
      window.BOKAMERA.configuration.targetOrigin
        ? window.BOKAMERA.configuration.targetOrigin
        : ''; 
    const returnPage = container.dataset.returnPage;
    const iframeSrc = new URL(`${appUrl}?targetOrigin=${targetOrigin}/#/cancel-booking?id=&code=&returnPage=${returnPage}`);
    
    iframe.setAttribute(
      "src",
      iframeSrc.toString()
    );

    iframe.setAttribute("frameBorder", "0");
    iframe.setAttribute("scrolling", "no");
    iframe.style.width = "100%";

    container.appendChild(iframe);

    iframeResize(
      {
        heightCalculationMethod: "max",
        checkOrigin: false,
      },
      "#bokamera-embedded-profile > iframe"
    );

    removeButtons();
  };
  container.appendChild(loginButton);

  if(cancelButtonEnabled) {
    container.appendChild(cancelBookingButton);
  }
}

function removeButtons() {
  if(document.getElementById(CANCEL_BOOKING_BTN_ID)) {
    document.getElementById(CANCEL_BOOKING_BTN_ID).remove();
  }

  if(document.getElementById(LOGIN_BTN_ID)) {
    document.getElementById(LOGIN_BTN_ID).remove();
  }
}

function startApp(backUri = "") {
  const iframe = document.createElement("iframe");
  const container = document.getElementById(CONTAINER_ID);

  const targetOrigin = container && container.dataset ? container.dataset.targetOrigin : '';

  if(!container) {
    console.error(`You must have an element with an id of bokamera-embedded-profile`)
    return;
  }

  iframe.onload = () => {
    iframe.style.height = KEYCLOAK_LOGIN_IFRAME_HEIGHT;
  }

  let configuration = {};
  if (window.BOKAMERA && window.BOKAMERA.configuration) {
    configuration = window.BOKAMERA.configuration;
  }

  if (!configuration.targetOrigin && targetOrigin) {
    configuration.targetOrigin = targetOrigin;
  }

  if(backUri) {
    const extendedConfiguration = {...configuration, backUri};
    const iframeSrc = new URL(process.env.REACT_APP_BASE_URL);
    iframeSrc.search = qs.stringify(extendedConfiguration);
    iframeSrc.searchParams.append(
      backUri,
      `${window.location.origin}${window.location.pathname}`
    );
    window.location.replace(iframeSrc)
  } else {
    const iframeSrc = new URL(process.env.REACT_APP_BASE_URL);
    iframeSrc.search = qs.stringify(configuration);

    iframe.setAttribute(
      "src",
      iframeSrc.toString()
    );

    iframe.setAttribute("frameBorder", "0");
    iframe.setAttribute("scrolling", "no");
    iframe.style.width = "100%";
  
    container.appendChild(iframe);
  
    iframeResize(
      {
        heightCalculationMethod: "max",
        checkOrigin: false,
      },
      "#bokamera-embedded-profile > iframe"
    );

    }
}

function closeApp() {
  const container = document.getElementById(CONTAINER_ID);
  const url = new URL(window.location.href);
  url.searchParams.delete('success');
  window.location.href = url.href;

  container.innerHTML = "";
}

function init() {
  if (
    qs.parse(window.location.search, { ignoreQueryPrefix: true }).action ===
    "login-required" ||
    qs.parse(window.location.search, { ignoreQueryPrefix: true }).success
  ) {
    addOrchestratorScript();
    startApp();
  } else {
    addOrchestratorScript();
    addButtons()
  }
}

init();

// global methods
window.restartProfileApp = function() {
  closeApp();
  addButtons();
}
window.addButtons = addButtons;
window.startApp = startApp;
window.closeApp = closeApp;
